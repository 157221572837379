import React from 'react';
import dynamic from 'next/dynamic';

const WrappedNavigationPanel = dynamic(() => import('../overwrites/NavigationPanel'), {
  ssr: false,
});

const NavigationPanel = ({ isOpen = false, onClose, hasHeader = false, hasResponsiveItem = false, children, hasDisabledAnimations = false, logoLink = '/', items, hasCloseButton = true }) => {
  return <WrappedNavigationPanel items={items} isOpen={isOpen} onClose={onClose} hasHeader={hasHeader} hasResponsiveItem={hasResponsiveItem} hasDisabledAnimations={hasDisabledAnimations} logoLink={logoLink} hasCloseButton={hasCloseButton} />;
};

export default NavigationPanel;
