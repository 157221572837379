import Head from 'next/head';
import { useContext } from 'react';
import { GlobalContext } from '../../pages/_app';
import { getStrapiMedia } from '../../lib/media';
import { useRouter } from 'next/router';

const Seo = ({ siteSeo, documentData, pageType }) => {
  const router = useRouter();
  const { seo, siteName } = useContext(GlobalContext);
  const seoWithDefaults = {
    ...seo,
    ...(siteSeo ?? {}),
  };
  const fullSeo = {
    ...seoWithDefaults,
    // Add title suffix
    metaTitle: `${seoWithDefaults.metaTitle} | ${siteName}`,
    // Get full image URL
    shareImage: getStrapiMedia(seoWithDefaults.shareImage),
  };

  if (!fullSeo.metaSocial?.length) {
    fullSeo.metaSocial = seo?.metaSocial ?? [];
  }

  //console.log(siteName)
  //console.log(seo);
  //console.log('seo', seo, siteSeo, fullSeo, documentData);
  //console.log(siteSeo)

  return (
    <Head>
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={`https://${APP_SETTINGS.appUris.slice(-1)[0]}${router.asPath}`}></meta>
      <meta name="publisher" content="ARD.de" />
      {fullSeo?.keywords && <meta name="keywords" content={fullSeo.keywords.replace(', ', ',')} />}

      {pageType === 'ARTICLE' && (
        <>
          <meta property="og:type" content="article" />
          {documentData?.attributes?.publishedAt && <meta property="article:published_time" content={documentData?.attributes?.publishedAt} />}
          {documentData?.attributes?.publishedAt && <meta property="article:modified_time" content={documentData?.attributes?.updatedAt} />}
          {documentData?.attributes?.authors?.data?.length > 0 && <meta property="article:author" content={documentData?.attributes?.authors?.data.map((ad) => ad.attributes.name).join(', ')} />}
        </>
      )}
      {pageType !== 'ARTICLE' && <meta property="og:type" content="website" />}

      {(fullSeo.metaTitle || seo?.metaTitle) && (
        <>
          <title>{fullSeo.metaTitle ?? seo?.metaTitle}</title>
          <meta property="og:title" content={fullSeo.metaTitle ?? seo?.metaTitle} />
          <meta name="twitter:title" content={fullSeo.metaTitle ?? seo?.metaTitle} />
        </>
      )}
      {(fullSeo.metaDescription || seo?.metaDescription) && (
        <>
          <meta name="description" content={fullSeo.metaDescription ?? seo?.metaDescription} />
          <meta property="og:description" content={fullSeo.metaDescription ?? seo?.metaDescription} />
          <meta name="twitter:description" content={fullSeo.metaDescription ?? seo?.metaDescription} />
        </>
      )}
      {fullSeo.metaImage?.data?.attributes?.url && (
        <>
          <meta property="og:image" content={fullSeo.metaImage.data.attributes.url} />
          <meta name="twitter:image" content={fullSeo.metaImage.data.attributes.url} />
          <meta name="image" content={fullSeo.metaImage.data.attributes.url} />
        </>
      )}
      {fullSeo.article && <meta property="og:type" content="article" />}
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};

export default Seo;
