import styled from 'styled-components';

const CookieBannerWrapper = styled.div.withConfig({
  displayName: 'CookieBannerWrapper',
  componentId: 'cookie-banner-wrapper',
})([
  `
    position: fixed;
    z-index: 1001;
	bottom: 0;
	transition: opacity 0.2s ease;
    width: 100%;

    display: flex;
    justify-content: center;

    > div {
        max-width: 1280px;
        padding: 24px 72px 24px 72px;
        margin: 0;
        justify-content: space-around;

        > div:first-of-type {
            padding: 0;

            > p, > p > a {
                display: inline;
            }

            > p > a {
                text-wrap: nowrap;
            }
        }

        @media screen and (min-width: 1224px) {
            height: 96px;
        }

        @media screen and (min-width: 961px) {
            > div:first-of-type {
                padding-right: 8px;
            }
        }


        @media screen and (max-width: 961px) {
            padding: 16px 16px;
            justify-content: start;

            .button-row {
                margin-top: 8px;
                > div {
                    justify-content: start;
                }

                button {
                    margin-top: 8px;
                }
            }
        }

        @media screen and (max-width: 481px) {
            justify-content: start;
        }
    }

    p {
        padding-bottom: 8px;
        white-space: break-spaces;
    }

    p, a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: center;
    }

    button:first-of-type {
        margin-right: 16px;
    }

    .button-row {
        display: flex;
        align-items: center;
        padding: 0;

        > div {
            justify-content: end;
            flex-wrap: nowrap;
            margin: 0;

            > button {
                &:hover {
                    background-color: #2d91ff !important;
                    box-shadow: none !important;
                }

                > span {
                    font-weight: 520;
                    line-height: 120%;
                    height: min-content;
                    padding: 13px 16px;
                }
            } 
        }
    }
`,
]);

export default CookieBannerWrapper;
