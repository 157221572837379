import React from 'react';
import dynamic from 'next/dynamic';

const WrappedSingleGuide = dynamic(() => import('@ard-online/component-styleguide/dist/components/SingleGuide/SingleGuide'), {
  ssr: false,
});

const SingleGuide = ({ id, items, isLoading, as, hasDisabledAnimations }) => {
  return <WrappedSingleGuide id={id} items={items} isLoading={isLoading} as={as} hasDisabledAnimations={hasDisabledAnimations} />;
};

export default SingleGuide;
