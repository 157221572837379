import { rgba, lighten, darken } from 'polished';
import { mediathekDarkArdTheme } from '../../components/styleguide-remapped/styleguide-remapped';
import LogoWhite from '../../assets/logos/white/Ard';
import merge from 'lodash.merge';

const colors = Object.freeze({
  color1: '#FFFFFF', // Primary
  color2: '#0071EE', // Secondary
  color3: '#001e50',
  color4: '#FFF',
  color5: '#FF6670',
  color6: '#e2e2e2',
  color7: '#000',
  color8: '#f00',
});

const themeSettings = {
  id: 'ard-helpdesk-dark',
  name: 'ARD Helpdesk',
  fontFamily: "'The Sans', 'TheSans C5', sans-serif",
  primary: {
    text: colors.color4,
    base: colors.color2,
    light: lighten(0.15, colors.color1),
    dark: colors.color1,
  },
  secondary: {
    text: colors.color4,
    base: colors.color2,
    light: lighten(0.15, colors.color2),
    dark: darken(0.15, colors.color2),
  },
  logo: {
    component: LogoWhite,
    width: 69,
    height: 38,
  },
  search: {
    text: colors.color7,
    input: colors.color4,
    glass: colors.color1,
    background: colors.color4,
  },
};

const additionalProperties = Object.freeze({
  relatedTopics: {
    background: '#243350',
  },
});

export default (() => {
  return { ...merge(mediathekDarkArdTheme, themeSettings), ...additionalProperties };
})();
