import React from 'react';
import dynamic from 'next/dynamic';

const WrappedPlayerInfo = dynamic(() => import('@ard-online/component-styleguide/dist/components/PlayerInfo/PlayerInfo'), {
  ssr: false,
});

const PlayerInfo = ({ title, subline, text, availableTo, maturityRating, broadcastTeaser, pictureCredit, logo, logoLink, logoLinkAs, isLive, startTime, endTime, nextBroadcastItems = [], hasContextMenu = false, contextMenuItems = [], onIconClick = () => {}, isLoading, isGeoblocked, isInWatchlist = false, hasDisabledAnimations }) => {
  return (
    <WrappedPlayerInfo
      title={title}
      subline={subline}
      text={text}
      availableTo={availableTo}
      maturityRating={maturityRating}
      broadcastTeaser={broadcastTeaser}
      pictureCredit={pictureCredit}
      logo={logo}
      logoLink={logoLink}
      logoLinkAs={logoLinkAs}
      isLive={isLive}
      startTime={startTime}
      endTime={endTime}
      nextBroadcastItems={nextBroadcastItems}
      hasContextMenu={hasContextMenu}
      contextMenuItems={contextMenuItems}
      onIconClick={onIconClick}
      isLoading={isLoading}
      isGeoblocked={isGeoblocked}
      isInWatchlist={isInWatchlist}
      hasDisabledAnimations={hasDisabledAnimations}
    />
  );
};

export default PlayerInfo;
