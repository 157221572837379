import React, { SVGProps } from 'react';

const SvgArd = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 68" {...props}>
		<title>{'ARD-Logo'}</title>
		<path
			style={{
				fill: '#fff',
				fillRule: 'evenodd',
			}}
			d="M100.6 41.7V20.6l-21.2 7.6v5.3l7-2.6v15.9l14.2-5.1z"
		/>
		<path
			d="M92.5 8.3a26.1 26.1 0 0 0-21.2 11l3.8 2.6a21.2 21.2 0 0 1 17.3-9.1A20.9 20.9 0 0 1 113.2 34a20.9 20.9 0 0 1-20.8 21.2 21.2 21.2 0 0 1-17.3-9.1l-3.8 2.6a25.2 25.2 0 0 0 20.9 11 25.7 25.7 0 0 0 .3-51.4"
			style={{
				fill: '#fff',
				fillRule: 'evenodd',
			}}
		/>
		<path
			d="M59.9 22.1h-7.7v23.6h7c8.1 0 12.3-4.1 12.3-12S67.5 22 59.9 22m-.3 19.7-2.7-.2V26.2h2.7c4.7 0 7 2.6 7 7.9s-2.2 7.8-7 7.8M20 22.3h-6L6 45.7h4.8l1.5-4.8h8.9l1.5 4.8h5.2Zm-6.4 14.6 2.3-7.2a26.3 26.3 0 0 0 .8-3c.2.8.5 1.8.9 3.1l2.3 7.1ZM44 37.6l-1.1-1.7a6.7 6.7 0 0 0 4.8-6.6c0-4.7-3-7.2-8.5-7.2h-8.4v23.6h4.7v-8.9h2.6l5.3 8.9H49Zm-8.5-11.5h3.3c2.6 0 3.9 1.1 3.9 3.4s-1.4 3.3-4 3.3h-3.2Z"
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);

export default SvgArd;
