import styled from 'styled-components';

export const MainContent = styled.div.withConfig({})([`
    overflow-x: hidden;
    clear: both;
    position: relative;
    min-height: calc(100vh - 96px);
`]);

export const FooterContent = styled.div.withConfig({
    displayName: 'FooterContent',
    componentId: 'footer-content'
})([`
    width: 100%;
    font-weight: 520;
    line-height: 130%;
    height: 96px;

    display: flex;
    justify-content: center;

    div.bg-footer-image-wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        height: 96px;
        overflow: hidden;
        display: flex;
        z-index: -1;
    }

    @media screen and (max-width: 642px) {
        height: 136px;

        div.bg-footer-image-wrapper {
            height: 136px;
        }
    }
    
    a {
        color: #fff;
        line-height: 120%;
        
        margin: 8px 0;
    }

    > div {
        max-width: 1136px;
        justify-content: space-between;
        padding: 0 16px;
        margin: 0;

        @media screen and (max-width: 961px) {
            padding: 0 32px;
        }

        @media screen and (max-width: 481px) {
            padding: 0 16px;
        }

        > div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            > a {
                margin-right: 32px;
            }
        }
    }

    div > div > svg {
        height: 65px;
    }
`]);

export const PrimaryLayoutWrapper = styled.div.withConfig({
    displayName: 'PrimaryLayoutWrapper',
    componentId: 'primary-layout-wrapper'
})([`
    max-width: 1136px;
    margin: 16px auto 0;
    overflow-x: hidden;
    clear: both;
    padding: 16px 16px 32px 16px;

    @media screen and (max-width: 540px) {
        padding-bottom: 178px;
    }
`]);

export const PrimarySearchBarWrapper = styled.div.withConfig({
    displayName: 'PrimarySearchBarWrapper',
    componentId: 'primary-search-bar-wrapper'
})([`
    background: radial-gradient(92.91% 111.89% at 50.18% -0.00%,#05489E 0%,#003480 49.04%,#002760 100%) !important;
    padding: 146px 16px 0;
    margin: -16px -16px 32px;
    position: relative;
    width: 100%;
    z-index: 100;
    height: 212px;

    @media screen and (max-width: 481px) {
        height: 200px;
    }

    h1 {
        color: #fff;
    }

    .hidden-searchbar-element {
        opacity: 0;
        button {
            cursor: default;
        }
    }

    .breadcrumb-row {
        .chip > a {
            color: #FFF !important;
            background: rgba(255, 255, 255, 0.20);
            box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.02);

            @media (hover: hover) {
                &:hover {
                    background: #2D91FF !important;
                    box-shadow: inset 0 0 0 0 #2D91FF !important;
                }
            }
        }
    } 
`]);

export const FooterLogo = styled.div.withConfig({
    displayName: 'FooterLogo',
    componentId: 'footer-logo'
})([`
	> svg { height: 48px; float: right; }
`]);

export const NotFoundWrapper = styled.div.withConfig({
    displayName: 'NotFoundWrapper',
    componentId: 'not-found-wrapper'
})([`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;

    h2 {
        font-style: normal;
        font-weight: 530;
        font-size: 36px;
        line-height: 120%;
        text-align: center;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
    }
`]) 
