import React from 'react';
import dynamic from 'next/dynamic';
import { Teaser } from '../styleguide-remapped';

const WrappedSwiper = dynamic(() => import('@ard-online/component-styleguide/dist/components/Swiper/Swiper'), {
  ssr: false,
});

const Swiper = ({ id, title, hasVisibleTitle = true, titleLink, type = 'default', hasAutoplay = type === 'stage', size = 'm', ratio = '16x7', stageRatio = 'default', hoverEffect = 'default', items, hasVirtualSlides = false, hasDisabledAnimations, onTeaserClick, onTeaserVisible, itemAs = Teaser, isLoading }) => {
  return <WrappedSwiper id={id} title={title} hasVisibleTitle={hasVisibleTitle} titleLink={titleLink} type={type} hasAutoplay={hasAutoplay} size={size} ratio={ratio} stageRatio={stageRatio} hoverEffect={hoverEffect} items={items} hasVirtualSlides={hasVirtualSlides} hasDisabledAnimations={hasDisabledAnimations} onTeaserClick={onTeaserClick} onTeaserVisible={onTeaserVisible} itemAs={itemAs} isLoading={isLoading} />;
};

export default Swiper;
