import React from 'react';
import dynamic from 'next/dynamic';
import { H3 } from '../../styleguide-remapped/styleguide-remapped';
import { WrappedPlayerContainer } from './PlayerContainerStyles.js';

const WrappedPlayer = dynamic(() => import('@ard-online/component-styleguide/dist/components/Player/Player'), {
  ssr: false,
});

const Player = ({ playerConfiguration, mediaCollection, image, getArdPlayer, isLoading, blockedBy, ageVerificationMode, onAgeVerification }) => {
  playerConfiguration._baseUrl = `${APP_SETTINGS.playerConfigOrigin}/`;
  playerConfiguration._autoplay = false;

  return (
    <>
      <H3>{mediaCollection._title}</H3>
      <WrappedPlayerContainer>
        <WrappedPlayer mediaCollection={mediaCollection} playerConfiguration={playerConfiguration} image={image} getArdPlayer={getArdPlayer} isLoading={isLoading} blockedBy={blockedBy} ageVerificationMode={ageVerificationMode} onAgeVerification={onAgeVerification} />
      </WrappedPlayerContainer>
    </>
  );
};

export default Player;
