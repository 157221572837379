import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/navigation';
import { MainContent } from '../../assets/css/style';
import Seo from '../../components/custom/seo';
import Footer from './footer';
import CookieBanner from '../custom/cookie-banner/cookie-banner';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { Toolbar, FontStyles, FontSizes, ArrowLeftIcon } from '../../components/styleguide-remapped/styleguide-remapped';
import { default as ardHelpdeskTheme } from '../themes/ardHelpdeskTheme';
import { default as ardHelpdeskThemeDark } from '../themes/ardHelpdeskThemeDark';
import settings from '../../settings.json';
import { fetchSearchAPI, removeSavedUserSearchResultSelection, saveUserSearchResultSelection } from '../../lib/api';
import { setCookie, getCookie } from 'cookies-next';
import { GlobalContext } from '../../pages/_app';
import { trackSearchResultClick } from '../../scripts/tracking/tracking';
import { usePathname } from 'next/navigation';

const Layout = ({ children, seo, documentData, is404 = false, hideToolbar = false, hideNav = false, hideSearch = true, pageType = 'WEBSITE' }) => {
  const redirectTo404 = !((usePathname()?.includes('404')) ?? true) && is404;
  const router = useRouter();

  useEffect(() => {
    if (redirectTo404) {
      router.push('/404/');
    }
  }, [redirectTo404, router]);

  const { selectedTheme } = useContext(GlobalContext);
  const toolbarConfig = {
    /*actionIconsLeft: hideNav ? [] : [
      { icon: "back", type: "tab", onClick: () => router.push('/') },
    ],*/
    items: hideNav ? [] : [{ text: 'ARD Hilfe', type: 'text', isActive: false, onClick: () => router.push('/') }],
    actionIconsRight: hideSearch
      ? []
      : [
        {
          icon: 'search',
          type: 'tab',
          onClick: () => { },
        },
      ],
    hasBetaLabel: settings.betaMode,
    fixed: true,
    isTransparent: true,
    logoButton: {
      onClick: () => {
        router.push('/');
      },
    },
    hasSearchBar: !hideSearch,
    hasLogo: true,
    hasCenteredLogo: true,
    hasDisabledAnimations: true,
  };

  const _darkThemeParam = 'dark';
  const _lightThemeParam = 'light';

  const [theme, setTheme] = useState(selectedTheme === _lightThemeParam ? ardHelpdeskTheme : ardHelpdeskThemeDark);
  const [toolbarVisible, setToolbarVisible] = useState(true);
  const [searchedWord, setSearch] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchHasFocus, setSearchHasFocus] = useState(false);

  const setThemeParameters = async (theme) => {
    setTheme(theme === _lightThemeParam ? ardHelpdeskTheme : ardHelpdeskThemeDark);
    setCookie('theme', theme, { secure: settings.prodMode, maxAge: 31536000, path: '/' });
    document.body.className = `${theme}-theme`;

    if (!hideToolbar && !toolbarVisible) {
      setToolbarVisible(true);
    }
  };

  const searchWithMeili = async () => {
    const searchResult = await fetchSearchAPI(
      searchedWord,
      (e, hit, data) => {
        if (e === 'cleared') {
          setSearchHasFocus(false);
          removeSavedUserSearchResultSelection(hit);
          searchWithMeili();
        } else {
          saveUserSearchResultSelection(hit);
          if (data?.query) {
            trackSearchResultClick(window, data.query, data.estimatedTotalHits ?? 0, hit.id, (data.hits.reverse().findIndex((d) => d.id === hit.id) ?? -1) + 1, true);
          }
          window.location.href = `/artikel/${hit.slug}`;
        }
      },
      7,
      false,
      window
    );
    setSearchSuggestions(searchResult);
  };

  useEffect(() => {
    if ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || getCookie('theme') === _darkThemeParam) {
      setThemeParameters(_darkThemeParam);
    } else if (getCookie('theme') !== _lightThemeParam) {
      setThemeParameters(_lightThemeParam);
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      event.matches ? setThemeParameters(_darkThemeParam) : setThemeParameters(_lightThemeParam);
    });
  });

  useEffect(() => {
    if (!hideSearch) {
      searchWithMeili();
    }
  }, [searchedWord, hideSearch]);
  return (
    <div>
      {<Seo siteSeo={seo} pageType={pageType} documentData={documentData} />}
      <ThemeProvider theme={theme}>
        <Normalize />
        <FontStyles />
        <FontSizes />
        {toolbarVisible && (
          <div className="toolbar">
            <Toolbar {...toolbarConfig} searchHasFocus={searchHasFocus} onSearchInputChange={(e) => setSearch(e)} searchSuggestions={searchSuggestions} theme={ardHelpdeskTheme} />
          </div>
        )}
        <MainContent hasPrimarySearch={true} className={`main-container ${pageType?.toLowerCase()}-main-container`}>
          {children}
        </MainContent>
        <CookieBanner />
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default Layout;
