import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Root as ButtonRoot, IconWrapper } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';
import { Root as NavbarRoot, ActiveBar as NavbarActivebar, SubNav as NavbarSubNav, ButtonWrapper as NavbarButtonWrapper } from '@ard-online/component-styleguide/dist/components/Navbar/styles';
import { animated } from 'react-spring';
import { cssEaseInOut } from '@ard-online/component-styleguide/dist/components/primitives/Easing';
import { RgbaColor } from 'polished/lib/types/color';

export const Root = styled.div<{ fixed: boolean }>`
  width: 100%;
  min-width: 320px;
  float: left;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 998;

  a,
  button {
    color: ${(props) => props.theme.toolbar.text};
    fill: ${(props) => props.theme.toolbar.text};
    padding: 0;
  }

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
    `};
`;

export const SearchToolbarWrapper = styled(animated.span)<{ $headerInitialized: boolean }>`
  padding-right: 16px !important;
  margin-top: 10px;

  ul[role='listbox'] {
    position: absolute;
    top: 40px;
    z-index: 1;
    width: calc(30vw - 0px);
    text-align: left;
    right: 0;

    li > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        display: inline-block;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  label > div {
    button > svg {
      background-color: rgba(0, 0, 0, 0.25);
    }

    svg {
      fill: #000;
    }

    > input {
      width: 30vw;
    }
  }

  @media screen and (max-width: 624px) {
    padding-left: 16px !important;
    width: 100%;

    ul[role='listbox'] {
      width: 100%;
    }

    label > div > input {
      width: 100%;
    }
  }

  @media only screen and (max-width: 624px) {
    ${(props) =>
      !props.$headerInitialized &&
      css`
        display: none;
      `};
  }
`;

export const ToolbarWrapper = styled.div<{ hasNavigation: boolean; toolbarHeight: number; isTransparent: boolean }>`
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  top: 0;
  position: relative;
  box-sizing: border-box;

  a,
  button {
    color: ${(props) => props.theme.toolbar.text} !important;
  }

  ${(props) =>
    props.hasNavigation &&
    css`
      padding: 0 10px;
      box-sizing: border-box;

      :after {
        display: none;
      }
    `};

  ${NavbarRoot} {
    position: unset;
  }

  ${NavbarButtonWrapper} {
    z-index: unset;
  }

  ${NavbarSubNav} {
    background: ${(props) => props.theme.toolbar.background};

    :after {
      background: ${(props) => props.theme.toolbar.background};
    }
  }

  ${(props) =>
    props.isTransparent &&
    css`
      padding: 16px 8px 8px;
      height: ${props.toolbarHeight}px;
    `};
`;

function generateGradient(color: string | number | RgbaColor) {
  return `
	linear-gradient(
		to bottom,
		${rgba(color, 0.7)} 0%,
		${rgba(color, 0.691)} 11.2%,
		${rgba(color, 0.666)} 21.4%,
		${rgba(color, 0.627)} 30.7%,
		${rgba(color, 0.577)} 39%,
		${rgba(color, 0.519)} 46.6%,
		${rgba(color, 0.454)} 53.5%,
		${rgba(color, 0.385)} 59.8%,
		${rgba(color, 0.315)} 65.5%,
		${rgba(color, 0.315)} 65.6%,
		${rgba(color, 0.246)} 71%,
		${rgba(color, 0.181)} 76.1%,
		${rgba(color, 0.123)} 80.9%,
		${rgba(color, 0.073)} 85.7%,
		${rgba(color, 0.034)} 90.4%,
		${rgba(color, 0.009)} 95.1%,
		${rgba(color, 0)} 100%
	)
	`;
}

export const Background = styled.div<{ isTransparent: boolean; hasSearchBar: boolean }>`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  ${(props) =>
    props.hasSearchBar &&
    css`
      background: radial-gradient(92.91% 111.89% at 50.18% -0%, #05489e 0%, #003480 49.04%, #002760 100%) !important;
    `};

  ${(props) =>
    props.isTransparent &&
    css`
      box-shadow: none;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 8px;
        background-color: ${(props) => props.theme.toolbar.accent};
      }
    `};
`;

export const DarkerBackground = styled(Background)`
  opacity: 0;
  transition: 0.5s all ${cssEaseInOut};
  background: radial-gradient(92.91% 111.89% at 50.18% -0%, #05489e 0%, #003480 49.04%, #002760 100%) !important;
`;

export const ActionIcons = styled(animated.div)`
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  a,
  button {
    text-decoration: none !important;
  }

  ${ButtonRoot} {
    @media only screen and (max-width: 1023px) {
      padding: 0;

      ${IconWrapper} {
        margin: 0;
      }

      span {
        padding: 0;
        width: 0;
      }
    }
  }
`;

export const Title = styled.div`
  flex-grow: 1;
  //overflow: hidden;
  display: flex;
  align-items: center;
  //z-index: 1;
  background-color: transparent;

  h2 {
    font-size: 1.2rem;
    display: inline;
    font-weight: 500;
    color: ${(props) => props.theme.toolbar.text};
    padding: 0 10px;
  }

  ${NavbarActivebar} {
    background: ${(props) => props.theme.toolbar.text};
  }
`;

export const Mainmenu = styled(animated.div)<{ $hasNavigation: boolean }>`
  position: relative;

  ${(props) =>
    props.$hasNavigation &&
    css`
      @media only screen and (max-width: 1023px) {
        display: none;
      }
    `};
`;

export const LogoWrapper = styled(animated.div)<{ $hasCenteredLogo: boolean; $headerInitialized: boolean }>`
  ${(props) =>
    props.$hasCenteredLogo &&
    css`
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      padding: 0 !important;
    `};

  @media only screen and (max-width: 624px) {
    ${(props) =>
      !props.$headerInitialized &&
      css`
        display: none;
      `};
  }
`;

export const Logo = styled.div`
  height: 48px;
  padding: 0 10px;
  transition: padding 0.5s ease-in-out;
  -webkit-appearance: none; // please do not delete, necessary for Safari

  @media only screen and (max-width: 420px) {
    height: 38px;
  }

  svg {
    width: auto;
    height: 100%;
  }

  &&& {
    svg,
    path {
      color: ${(props) => props.theme.toolbar.text} !important;
      fill: ${(props) => props.theme.toolbar.text} !important;
    }
  }
`;

export const BetaLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ButtonRoot} {
    span {
      color: ${(props) => props.theme.betaLabel.text};
      font-size: 0.85rem;
      padding: 6px 9px;
      height: auto;
      line-height: 1;
    }

    :hover {
      cursor: text;
      text-decoration: none;
    }
  }
`;

export const AnimationWrapper = styled.div<{ hasNavigation: boolean }>`
  display: grid;
  z-index: 1;
  position: relative;
  height: 100%;
  align-self: center;

  ${(props) =>
    props.hasNavigation &&
    css`
      height: auto;
    `};
`;

export const ProfileImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;

  ${ImageRoot} {
    width: 38px;
    height: 38px;
    margin: 0 auto;
    img {
      display: none;
    }

    img[src*='http'] {
      display: block;
    }
  }
`;
