import React from 'react';
import dynamic from 'next/dynamic';

const WrappedPlayerScalable = dynamic(() => import('@ard-online/component-styleguide/dist/components/PlayerScalable/PlayerScalable'), {
  ssr: false,
});

const PlayerScalable = ({ playerConfiguration, mediaCollection, getArdPlayer, image, shrinkFactor = 0.55, isLoading, blockedBy, ageVerificationMode, onAgeVerification }) => {
  return <WrappedPlayerScalable playerConfiguration={playerConfiguration} mediaCollection={mediaCollection} getArdPlayer={getArdPlayer} image={image} shrinkFactor={shrinkFactor} isLoading={isLoading} blockedBy={blockedBy} ageVerificationMode={ageVerificationMode} onAgeVerification={onAgeVerification} />;
};

export default PlayerScalable;
