import React from 'react';
import dynamic from 'next/dynamic';

const WrappedGallery = dynamic(() => import('../overwrites/Gallery/Gallery'), {
  ssr: false,
});

const Gallery = ({ id, headline, hasAutoplay, hasFullscreen, hasLoop, items }) => {
  return <WrappedGallery id={id} items={items} />;
};

export default Gallery;
