import styled from 'styled-components';

export const WrappedPlayerContainer = styled.div.withConfig({
  displayName: 'WrappedPlayerContainer',
  componentId: 'wrapped-player-container',
})([
  `
    width: 100%;
    height: calc(900px * 9 / 16);
    margin-top: 20px;

    @media (max-width:930px){
		height: calc((100vw - 20px) * 9 / 16) !important;
	}
`,
]);
