import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTransition } from 'react-spring';
import { ActionIcons, AnimationWrapper, Background, BetaLabel, DarkerBackground, Logo, LogoWrapper, Mainmenu, ProfileImageWrapper, Root, Title, ToolbarWrapper, SearchToolbarWrapper } from './styles';
import dynamic from 'next/dynamic';
import { ThemeContext } from 'styled-components';
import { H2 } from '@ard-online/component-styleguide/dist/components/primitives/Headlines';
import { ContextMenu } from '@ard-online/component-styleguide/dist/components/ContextMenu/ContextMenu';
import Button, { Props as ButtonProps } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { Navbar, NavbarItemProps } from '@ard-online/component-styleguide/dist/components/Navbar/Navbar';
import MenuIcon from '@ard-online/component-styleguide/dist/assets/images/icons/Menu';
import AccountIcon from '@ard-online/component-styleguide/dist/assets/images/icons/Account';
import SearchIcon from '@ard-online/component-styleguide/dist/assets/images/icons/Search';
import BackIcon from '@ard-online/component-styleguide/dist/assets/images/icons/Back';
import CloseIcon from '@ard-online/component-styleguide/dist/assets/images/icons/Close';
import { AnyObject, AnyFunction } from '@ard-online/component-styleguide/dist/types';
import clickOnEnter from '@ard-online/component-styleguide/dist/components/shared/clickOnEnter';
import Initials from '@ard-online/component-styleguide/dist/components/primitives/Initials';
import Image from '@ard-online/component-styleguide/dist/components/Image/Image';
const SearchInput = dynamic(() => import('@ard-online/component-styleguide/dist/components/SearchInput/SearchInput'), { ssr: false });

type ActionIconsProps = {
  icon: string;
  hasDisabledAnimations?: boolean;
  src?: string;
} & Omit<ButtonProps, 'type' | 'isIconOnly' | 'iconLeft' | 'toggleIconLeft' | 'id' | 'ariaControls' | 'ariaExpanded'>;

export type Props = {
  /** Zeigt ein Titel an */
  title?: string;
  /** Zeigt ein Logo an */
  hasLogo?: boolean;
  /** Platziert das Logo mittig in der Toolbar */
  hasCenteredLogo?: boolean;
  /** Macht aus dem Logo einen Button (iconLeft wird automatisch durch das SenderLogo ersetzt) */
  logoButton?: ButtonProps;
  /** Die Icons werden am linken Rand der Toolbar angezeigt => menu | account */
  actionIconsLeft?: Array<ActionIconsProps>;
  /** Die Icons werden am rechten Rand der Toolbar angezeigt => search | close */
  actionIconsRight?: Array<ActionIconsProps>;
  /** Items für Account-Navigation */
  accountNavigationItems?: Array<ButtonProps>;
  /** Bestimmt ob die Toolbar am oberen Rand fixiert ist oder nicht */
  fixed?: boolean;
  /** Items für Main- oder Submenü (Button) */
  items?: Array<
    ButtonProps & {
      hasDisabledAnimations?: boolean;
    }
  >;
  /** BETA Kennzeichnung */
  hasBetaLabel?: boolean;
  /** Deaktiviert alle Animationen. */
  hasDisabledAnimations?: boolean;
  /** Transparenter Hintergrund mit Highlight Farbe */
  isTransparent?: boolean;
  /** Legt fest, ob die Selektion der Einträge ausschließlich durch die isActive-Property der einzelnen Items möglich ist. Nützlich z.B. zur Validierung oder um die Selektion programmatisch zu ändern */
  hasControlledItems?: boolean;
  /** Is called while Search-Input is changing */
  onSearchInputChange?: AnyFunction;
  /** Items für die SearchSuggestion Liste */
  searchSuggestions?: Array<any>;
  /** Legt fest ob eine Searchbar gerendert werden soll */
  hasSearchBar?: boolean;
  /** Legt fest ob eine Searchbar focus hat */
  searchHasFocus?: boolean;
  /** Legt das zu verwendende Theme fest */
  theme?: any;
};

// setIcons ActionIcon Left and Right
function getIcon(accountNavigationItems: Array<ButtonProps>, hasDisabledAnimations: boolean, name?: string): AnyObject {
  switch (name) {
    case 'menu':
      return {
        icon: <MenuIcon />,
        text: 'Navigation',
        type: 'tab',
        id: 'navigationPanelVisible',
        ariaControls: 'navigationPanel',
        ariaExpanded: false,
      };
    case 'account':
      return {
        icon:
          accountNavigationItems.length > 0 ? (
            <ContextMenu items={accountNavigationItems} hasDisabledAnimations={hasDisabledAnimations}>
              <AccountIcon role="button" />
            </ContextMenu>
          ) : (
            <AccountIcon />
          ),
        text: 'Anmelden',
        type: 'tab',
        id: 'toolbarAccountButton',
        ariaControls: null,
        ariaExpanded: null,
      };
    case 'profile':
      return {
        icon: true,
        text: 'Meins',
        type: 'tab',
        id: 'toolbarAccountButton',
        ariaControls: null,
        ariaExpanded: null,
      };
    case 'search':
      return {
        icon: <SearchIcon />,
        text: 'Suche',
        type: 'tab',
        id: null,
        ariaControls: null,
        ariaExpanded: null,
      };
    case 'close':
      return {
        icon: <CloseIcon />,
        text: 'Schließen',
        type: 'tab',
        id: null,
        ariaControls: null,
        ariaExpanded: null,
      };
    case 'back':
      return {
        icon: <BackIcon />,
        text: 'Zurück',
        type: 'tab',
        id: null,
        ariaControls: null,
        ariaExpanded: null,
      };
    case 'text':
      return {
        text: 'Meins',
        type: 'tab',
        id: null,
        ariaControls: null,
        ariaExpanded: null,
      };
    default:
      return {
        icon: null,
        text: '',
        type: 'tab',
        id: null,
        ariaControls: null,
        ariaExpanded: null,
        contextMenuId: undefined,
      };
  }
}

function actionIconToButtonProps(actionIcon: ActionIconsProps, accountNavigationItems: Array<ButtonProps> = [], hasDisabledAnimations: boolean): ButtonProps {
  const { text, icon, id, ariaControls, ariaExpanded } = getIcon(accountNavigationItems, hasDisabledAnimations, actionIcon.icon);

  const actionIconProps: AnyObject = { ...actionIcon };
  delete actionIconProps.icon;
  delete actionIconProps.src;

  const InitialsIcon =
    accountNavigationItems.length > 0 ? (
      <ContextMenu items={accountNavigationItems} hasDisabledAnimations={hasDisabledAnimations}>
        <Initials tabIndex={0} onKeyUp={clickOnEnter} role="button">
          {actionIcon.text}
        </Initials>
      </ContextMenu>
    ) : (
      <Initials>{actionIcon.text}</Initials>
    );
  const ProfileIcon =
    accountNavigationItems.length > 0 ? (
      <ContextMenu items={accountNavigationItems} hasDisabledAnimations={hasDisabledAnimations}>
        <ProfileImageWrapper tabIndex={0} onKeyUp={clickOnEnter}>
          <Image src={actionIcon.src} isRound ratio="1x1" alt="Profilbild" />
        </ProfileImageWrapper>
      </ContextMenu>
    ) : (
      <ProfileImageWrapper>
        <Image src={actionIcon.src} isRound ratio="1x1" alt="Profilbild" />
      </ProfileImageWrapper>
    );
  return {
    ...actionIconProps,
    id,
    ariaControls,
    ariaExpanded,
    text: actionIcon.icon === 'account' && actionIcon.text ? 'Meins' : text,
    type: 'tab',
    iconLeft: actionIcon.icon === 'account' && actionIcon.text ? InitialsIcon : actionIcon.icon === 'profile' ? ProfileIcon : icon,
    isIconOnly: !(actionIcon.icon === 'account' && !actionIcon.text),
  };
}

function useNavbarItems(actionIcons: Array<ActionIconsProps>, accountNavigationItems: Array<ButtonProps>, hasDisabledAnimations: boolean): Array<AnyObject> {
  return useMemo(() => {
    return actionIcons
      .filter((actionIcon) => getIcon(accountNavigationItems, hasDisabledAnimations, actionIcon.icon).icon)
      .map((actionIcon) => ({
        ...actionIconToButtonProps(actionIcon, accountNavigationItems, hasDisabledAnimations),
      }));
  }, [actionIcons]);
}

function getFadeAnimation(hasDisabledAnimations: boolean, items: Array<AnyObject> = []): AnyObject {
  const skipAnimation = hasDisabledAnimations || items.some((item) => item.hasDisabledAnimations);
  return {
    from: { opacity: skipAnimation ? 1 : 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 },
    immediate: skipAnimation,
    keys: items.map(({ text }) => text).join(),
  };
}

function useNavbarItemsTransition(items: AnyObject[], hasDisabledAnimations: boolean) {
  const fadeAnimation = getFadeAnimation(hasDisabledAnimations, items);

  return useTransition({ items }, fadeAnimation);
}

function useSetOpacity(backgroundRef: AnyObject) {
  return useCallback(() => {
    if (backgroundRef.current && window.scrollY > 0) {
      backgroundRef.current.style.opacity = 1;
    } else if (backgroundRef.current) {
      backgroundRef.current.style.opacity = 0;
    }
  }, [backgroundRef]);
}

function getLogoItem(theme: AnyObject, logoButton?: ButtonProps, hasLogo?: boolean) {
  // SVG Logo
  const SvgLogo = theme.toolbar.logo;
  const LogoWidth = theme.toolbar.logoDimensions.width;
  const LogoHeight = theme.toolbar.logoDimensions.height;

  if (hasLogo) {
    if (!!logoButton) {
      return (
        <Button
          isIconOnly
          type={'tab'}
          {...logoButton}
          iconLeft={
            <Logo>
              <SvgLogo width={LogoWidth} height={LogoHeight} />
            </Logo>
          }
        />
      );
    } else {
      return (
        <Logo>
          <SvgLogo width={LogoWidth} height={LogoHeight} />
        </Logo>
      );
    }
  } else {
    return null;
  }
}

/* export ToolbarHeight to use in MT for calculation offset of ClickEffect-Animation */
export const TOOLBAR_HEIGHT = 82;

export function Toolbar({ title, hasLogo = false, hasCenteredLogo = true, logoButton, actionIconsLeft = [], actionIconsRight = [], accountNavigationItems = [], fixed = true, items = [], hasBetaLabel = false, hasDisabledAnimations = false, isTransparent = false, hasControlledItems = false, onSearchInputChange = (e) => {}, searchSuggestions = [], hasSearchBar = false, searchHasFocus = false, theme = useContext(ThemeContext) }: Props) {
  /** Variables */
  const [headerInitialized, setHeaderInitialized] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchedWord, setSearch] = useState('');
  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);
  const navbarItemsRight = useNavbarItems(actionIconsRight, accountNavigationItems, hasDisabledAnimations);
  const logoItem = getLogoItem(theme, logoButton, hasLogo);
  const navbarItemsRightAnimation = useNavbarItemsTransition(navbarItemsRight, hasDisabledAnimations);
  const fadeAnimation = getFadeAnimation(hasDisabledAnimations);
  const logoAnimation = useTransition({ logoItem }, fadeAnimation);
  const backgroundRef = useRef(null);
  const onScroll = useSetOpacity(backgroundRef);
  const onResize = () => {
    setShowMobileSearchBar(window.innerWidth <= 624 && hasSearchBar);
  };

  /** Construktor-Hook & Effect-Hooks */
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    setHeaderInitialized(true);
  }, []);

  /** JSX */
  return (
    <Root fixed={fixed}>
      <ToolbarWrapper hasNavigation={!!items} isTransparent={isTransparent} toolbarHeight={TOOLBAR_HEIGHT}>
        <AnimationWrapper hasNavigation={!!items}>{items?.length > 0 && (!showSearchBar || !showMobileSearchBar) && <Button {...items[0]} />}</AnimationWrapper>
        {hasBetaLabel && (
          <BetaLabel>
            <Button text="BETA" type={'primary'} ariaLabel="BETA Version" />
          </BetaLabel>
        )}
        {!showSearchBar && (
          <Title>
            {title && <H2>{title}</H2>}

            {!showMobileSearchBar &&
              logoAnimation((styles, item) => (
                <LogoWrapper style={styles} $hasCenteredLogo={hasCenteredLogo} $headerInitialized={headerInitialized}>
                  {item.logoItem}
                </LogoWrapper>
              ))}
          </Title>
        )}

        {hasSearchBar && (showSearchBar || !showMobileSearchBar) && (
          <SearchToolbarWrapper $headerInitialized={headerInitialized}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (showMobileSearchBar) {
                  setShowSearchBar(false);
                }
                window.location.href = `/suche/${encodeURIComponent(searchedWord)}/`;
              }}
            >
              <SearchInput
                label="Wie können wir helfen?"
                onChange={(e) => {
                  if (e) {
                    onSearchInputChange(e);
                  } else if (showMobileSearchBar) {
                    setShowSearchBar(false);
                  }

                  setSearch(e ?? '');
                }}
                searchSuggestions={searchSuggestions}
                hasFocus={searchHasFocus}
              />
            </form>
          </SearchToolbarWrapper>
        )}

        {showMobileSearchBar && (
          <AnimationWrapper hasNavigation={!!items}>
            {navbarItemsRightAnimation((styles, item) => {
              const searchItem = item.items?.find((d) => d.text === 'Suche');
              if (searchItem) {
                searchItem.onClick = () => {
                  setShowSearchBar(true && showMobileSearchBar);
                };
              }
              return <ActionIcons style={styles}>{item.items.length > 0 && !showSearchBar && <Navbar items={item.items} hasControlledItems hasDisabledAnimations={hasDisabledAnimations} label="Menüelemente Rechts" />}</ActionIcons>;
            })}
          </AnimationWrapper>
        )}
        <Background isTransparent={isTransparent} hasSearchBar={hasSearchBar} />
        {isTransparent && <DarkerBackground ref={backgroundRef} isTransparent={isTransparent} />}
      </ToolbarWrapper>
    </Root>
  );
}

export default Toolbar;
